import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { BuyUnlimitedPhotos } from './BuyUnlimitedPhotos';
import { Cohosts } from './Cohosts';
import { RemoveParty } from './RemoveParty';
import { RsvpFields } from './RsvpFields';
import s from './SettingsForm.module.scss';
import { UrlField } from './UrlField';

import { Box } from '#root/Components/Box';
import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Container } from '#root/Components/Container';
import { Flex, Item } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { LinkA } from '#root/Components/Link';
import { Loading } from '#root/Components/Loading';
import { PartyPlanChooser } from '#root/Components/PartyPlanChooser';
import { PremiumLabel } from '#root/Components/PremiumLabel';
import { useAutoSubmit } from '#root/hooks/use-auto-submit';
import { useFeature } from '#root/hooks/use-feature';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { IEditableParty, IPartySettings } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon } from '#root/utils/icons';
import { supports } from '#root/utils/party';

const defaultSettings = (party: IEditableParty): IPartySettings => {
  return {
    slug: party.slug,
    language: party.language,
    rsvpEnabled: party.rsvpEnabled,
    rsvpRestricted: party.rsvpRestricted,
    rsvpDeadlineEnabled: party.rsvpDeadlineEnabled,
    rsvpFields: party.rsvpFields,
    guestCountChoiceEnabled: party.guestCountChoiceEnabled,
    smsUseSenderNumber: party.smsUseSenderNumber,
    photosEnabled: party.photosEnabled,
    photosEnabledDate: party.photosEnabledDate,
    photosPublicEnabled: party.photosPublicEnabled,
    photosPublicDefaultEnabled: party.photosPublicDefaultEnabled,
    wishlistReservationsEnabled: party.wishlistReservationsEnabled,
    visibility: party.visibility,
  };
};
// const supports = feature => typeof settings[feature] !== "undefined";
export const SettingsForm: FC = () => {
  const party = useEditableParty();
  const photosFeatureEnabled = useFeature('PHOTOS');
  const [submitting, setSubmitting] = useState(false);
  const t = useTranslation();

  const { setError, control, handleSubmit, watch } = useForm<IPartySettings>({
    defaultValues: defaultSettings(party),
    mode: 'onBlur',
  });
  const onSubmit: SubmitHandler<IPartySettings> = async data => {
    if (submitting) return;
    setSubmitting(true);
    getService('settings')
      .save(party.id, data)
      .then(settings => {
        if (party.language !== settings.language) {
          (window as Window).location.reload();
        }
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          for (const [field, message] of Object.entries<string>(body.errors)) {
            setError(field as any, { type: 'custom', message });
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const baseUrl = 'invii.me/';

  const photosEnabled = watch('photosEnabled');
  const isPremium = party.plan !== 'free';

  const [expandedPlanChooser, setExpandedPlanChooser] = useState(false);

  useAutoSubmit(watch, handleSubmit, onSubmit);

  return (
    <Container marginBottom>
      <h2 className={s.title}>
        {t(Keys.titles.settings)}{' '}
        <Button
          onClick={() => setExpandedPlanChooser(prev => !prev)}
          variant="contained"
          disableElevation
          color={isPremium ? 'secondary' : 'primary'}
        >
          {!isPremium ? (
            <>{t(Keys.chooser.title)} …</>
          ) : (
            <b>{t(Keys.plan[party.plan]?.name ?? party.plan)}</b>
          )}
        </Button>
      </h2>
      {expandedPlanChooser && <PartyPlanChooser className={css.spaceBottomL} />}
      <form id="settings" onSubmit={handleSubmit(onSubmit)}>
        {supports(party, 'rsvp') && (
          <Box bordered marginBottom id="rsvp">
            <h3>{t(Keys.admin.settings.rsvp.title)}</h3>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>{t(Keys.admin.settings.rsvp.on_off.description)}</Item>
              <Item column>
                <Controller
                  control={control}
                  name="rsvpEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} {...rest} />}
                      label={t(Keys.admin.settings.rsvp.on_off.label)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.rsvp.restricted.description)}</p>
                <p className={s.sub}>{t(Keys.admin.settings.rsvp.restricted.description2)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="rsvpRestricted"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} {...rest} />}
                      label={t(Keys.admin.settings.rsvp.restricted.label)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.rsvp.off_su.description)}</p>
                <p className={s.sub}>{t(Keys.admin.settings.rsvp.off_su.description2)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="rsvpDeadlineEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} {...rest} />}
                      label={t(Keys.admin.settings.rsvp.off_su.label)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.rsvp.guest_count.description)}</p>
                <p className={s.sub}>{t(Keys.admin.settings.rsvp.guest_count.description2)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="guestCountChoiceEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} {...rest} />}
                      label={t(Keys.generic.activate)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <div
              className={clsx(
                s.featureDisabled,
                supports(party, 'rsvp_fields') && s.featureEnabled
              )}
            >
              <h3>
                {t(Keys.admin.settings.rsvp.extra.title)}
                <PremiumLabel isPremium={isPremium} blured={isPremium} />
              </h3>
              <p>{t(Keys.admin.settings.rsvp.extra.description)}</p>
              <RsvpFields control={control} />
            </div>
          </Box>
        )}
        <Box
          bordered
          marginBottom
          id="party-slug"
          className={clsx(s.featureDisabled, supports(party, 'custom_url') && s.featureEnabled)}
        >
          <h3>
            {t(Keys.admin.settings.custom_url.label)}{' '}
            <PremiumLabel isPremium={isPremium} blured={isPremium} />
          </h3>
          <Flex column bordered stackOnMobile>
            <Flex column gap>
              {party.slug ? (
                <InfoBox type="warning">
                  <b>Bemærk:</b> {t(Keys.admin.settings.custom_url.notice)}
                </InfoBox>
              ) : null}
              <span>
                {t(Keys.admin.settings.custom_url.description)}{' '}
                <LinkA href={`https://${baseUrl}${party.sid}`} target="_blank">
                  {`${baseUrl}${party.sid}`}
                </LinkA>
              </span>
              {party.slug && (
                <span>
                  {t(Keys.admin.settings.custom_url.current)}{' '}
                  <LinkA href={`https://${baseUrl}${party.slug}`} target="_blank">
                    {`${baseUrl}${party.slug}`}
                  </LinkA>
                </span>
              )}
            </Flex>

            <Item>
              <Controller
                control={control}
                name="slug"
                render={({ field: { onChange, value } }) => (
                  <UrlField sid={party.sid} slug={value} onChange={onChange} />
                )}
              />
            </Item>
          </Flex>
        </Box>
        {/* <Box
          bordered
          marginBottom
          id="visibility"
          className={clsx(s.featureDisabled, supports(party, 'visibility') && s.featureEnabled)}
        >
          <h3>{t(Keys.admin.settings.visibility.title)}</h3>
          <Controller
            name="visibility"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="open"
                  label={t(Keys.admin.settings.visibility.open)}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="password"
                  label={t(Keys.admin.settings.visibility.password)}
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value="restricted"
                  label={t(Keys.admin.settings.visibility.restricted)}
                />
              </RadioGroup>
            )}
          />
        </Box> */}
        {photosFeatureEnabled ? (
          <Box
            id="party-photos"
            bordered
            marginBottom
            className={clsx(s.featureDisabled, supports(party, 'photos') && s.featureEnabled)}
          >
            <h3>
              {t(Keys.admin.settings.photos.title)}{' '}
              <PremiumLabel isPremium={isPremium} blured={isPremium} />
            </h3>
            <BuyUnlimitedPhotos />
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.photos.enabled.description1)}</p>
                <p className={s.sub}>{t(Keys.admin.settings.photos.enabled.description2)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="photosEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} {...rest} />}
                      label={t(Keys.admin.settings.photos.enabled.switch)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.photos.enabled_date.description1)}</p>
              </Item>
              <Item column>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={getService('translation').getLocale()}
                >
                  <Controller
                    control={control}
                    name="photosEnabledDate"
                    render={({ field: { value, onChange } }) => (
                      <Flex align="center" gap className={css.spaceM}>
                        {value ? (
                          <IconButton
                            disabled={submitting}
                            color="error"
                            onClick={() => onChange(null)}
                            title={t(Keys.generic.clear)}
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : null}
                        <DatePicker
                          label={t(Keys.admin.settings.photos.enabled_date.label)}
                          slotProps={{
                            textField: { variant: 'outlined' },
                            openPickerButton: { size: 'small' },
                          }}
                          disabled={!photosEnabled}
                          disablePast
                          value={value ? parseISO(value) : null}
                          onChange={newDate => {
                            if (newDate && !isNaN(newDate.getTime())) {
                              onChange(newDate.toISOString());
                            } else if (!newDate) {
                              onChange(null);
                            }
                          }}
                        />
                      </Flex>
                    )}
                  />
                </LocalizationProvider>
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
              <Item grow>
                <p>{t(Keys.admin.settings.photos.public.description1)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="photosPublicEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch disabled={!photosEnabled} checked={value} {...rest} />}
                      label={t(Keys.admin.settings.photos.public.switch)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center">
              <Item grow>
                <p>{t(Keys.admin.settings.photos.public_default.description1)}</p>
              </Item>
              <Item column>
                <Controller
                  control={control}
                  name="photosPublicDefaultEnabled"
                  render={({ field: { value, ...rest } }) => (
                    <FormControlLabel
                      control={<Switch disabled={!photosEnabled} checked={value} {...rest} />}
                      label={t(Keys.admin.settings.photos.public_default.switch)}
                    />
                  )}
                />
              </Item>
            </Flex>
            <Flex bordered stackOnMobile align="center" className={css.showOnDesktop}>
              <Item grow>
                <p>
                  {t(Keys.admin.settings.photos.download.description)}{' '}
                  <a href={`/api/party/${party.id}/photos/download`} download="download.zip">
                    {t(Keys.admin.settings.photos.download.button)}
                  </a>
                </p>
                <p className={s.sub}>{t(Keys.admin.settings.photos.download.description2)} </p>
              </Item>
            </Flex>
          </Box>
        ) : null}
        <Box
          bordered
          marginBottom
          id="party-sms-sender"
          className={clsx(s.featureDisabled, supports(party, 'sms_sender') && s.featureEnabled)}
        >
          <h3>
            {t(Keys.admin.settings.sms_sender.title)}{' '}
            <PremiumLabel isPremium={isPremium} blured={isPremium} />
          </h3>
          <Flex bordered stackOnMobile align="center">
            <p>{t(Keys.admin.settings.sms_sender.description1)}</p>
            <Item column>
              <Controller
                control={control}
                name="smsUseSenderNumber"
                render={({ field: { value, ...rest } }) => (
                  <FormControlLabel
                    control={<Switch checked={value} {...rest} />}
                    label={t(Keys.admin.settings.sms_sender.switch)}
                  />
                )}
              />
            </Item>
          </Flex>
        </Box>
        <Box bordered marginBottom>
          <h3>{t(Keys.admin.settings.language.label)}</h3>
          <Flex bordered stackOnMobile align="center">
            <Item grow>
              <p>{t(Keys.admin.settings.language.description)}</p>
            </Item>

            <Item column>
              <Controller
                control={control}
                name="language"
                render={({ field }) => (
                  <Select size="small" {...field}>
                    <MenuItem value="da">{t(Keys.admin.settings.language.da)}</MenuItem>
                    <MenuItem value="en">{t(Keys.admin.settings.language.en)}</MenuItem>
                  </Select>
                )}
              />
            </Item>
          </Flex>
        </Box>
      </form>
      <Box
        bordered
        marginBottom
        id="party-cohosts"
        className={clsx(s.featureDisabled, supports(party, 'cohosts') && s.featureEnabled)}
      >
        <h3>
          {t(Keys.admin.settings.cohosts.title)}{' '}
          <PremiumLabel isPremium={isPremium} blured={isPremium} />
        </h3>
        <p>{t(Keys.admin.settings.cohosts.description)}</p>
        <Cohosts />
      </Box>
      <Button
        variant="contained"
        disableElevation
        color="primary"
        type="submit"
        disabled={submitting}
        form="settings"
      >
        {submitting ? <Loading /> : t(Keys.generic.save)}
      </Button>
      {party.isOwner ? (
        <div className={css.spaceTopL}>
          <ButtonDialog
            withCloseButton
            buttonText={t(Keys.admin.settings.remove.title)}
            ButtonProps={{ color: 'error' }}
          >
            {onClose => <RemoveParty onClose={onClose} partyId={party.id} />}
          </ButtonDialog>
        </div>
      ) : null}
    </Container>
  );
};
