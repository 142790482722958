import { Info, Person } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useRef, useState } from 'react';

import { EditRSVP } from './EditRSVP';
import s from './RSVP.module.scss';
import { RsvpMessageForm } from './RsvpMessageForm';

import type { IParty, IRsvp } from '#root/store/types';

import { Bubble } from '#root/Components/Bubble';
import { BubbleHeader } from '#root/Components/Bubble/BubbleHeader';
import { BubbleTime } from '#root/Components/Bubble/BubbleTime';
import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Flex, Item } from '#root/Components/Flex';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CaretDownIcon, CloseIcon, DoneIcon, EditIcon, LinkIcon } from '#root/utils/icons';

// Only to find if we have direct linked to a specific thread.
const threadId = new URLSearchParams(window.location.search).get('id');

export const RSVP: FC<{
  item: IRsvp;
  rsvpFields: IParty['rsvpFields'];
  refresh: () => void;
  showGuestCount: boolean;
}> = ({ item, rsvpFields, refresh, showGuestCount }) => {
  const t = useTranslation();
  // Default open the thread that we have linked to.
  const [collapsed, setCollapsed] = useState(item.id !== threadId);
  const threadRef = useRef<HTMLDivElement>(null);
  const open = useCallback(() => {
    setCollapsed(prev => !prev);

    setTimeout(() => {
      threadRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }, 10);
  }, []);

  const renderedRsvpFields = rsvpFields.filter(
    rsvpField => item.fields[rsvpField.id] !== undefined && item.fields[rsvpField.id] !== ''
  );

  return (
    <div className={s.thread} ref={threadRef}>
      <Bubble className={s.rsvp} color="primary" shadow bold>
        <BubbleHeader bordered={!!item.comment || !!renderedRsvpFields.length}>
          <div className={s.isComming}>
            {item.isComming ? <DoneIcon color="primary" /> : <CloseIcon color="error" />}
          </div>
          {showGuestCount && item.isComming ? (
            <Tooltip title={t(Keys.party.blocks.rsvp.is_comming_amount.label)}>
              <div className={s.guestCount}>
                <Person fontSize="small" />
                {item.numberOfPeople}
              </div>
            </Tooltip>
          ) : null}

          <div className={s.name}>
            {item.contact ? (
              <Tooltip title={t(Keys.admin.rsvp.connected)}>
                <span>
                  <Flex gap align="center">
                    <span>{item.contact.name}</span>
                    <LinkIcon />
                  </Flex>
                </span>
              </Tooltip>
            ) : (
              item.name
            )}
          </div>
          <BubbleTime dateTime={item.createdAt} className={css.hideOnMobile} />

          <Item pushRight className={s.editButton}>
            <ButtonDialog
              withCloseButton
              icon={<EditIcon fontSize="small" />}
              header={t(Keys.admin.rsvp.edit)}
              IconButtonProps={{ size: 'small' }}
            >
              {onClose => <EditRSVP item={item} onClose={onClose} refresh={refresh} />}
            </ButtonDialog>
          </Item>
        </BubbleHeader>
        {item.comment ? <div className={s.comment}>{item.comment}</div> : null}
        {renderedRsvpFields.length > 0 && item.isComming ? (
          <div className={s.fields}>
            <Flex column gap>
              {renderedRsvpFields.map(({ id, name, type }) => (
                <Flex align="center" gap key={id}>
                  <b>{name}:</b>{' '}
                  {type === 'text' ? (
                    item.fields[id]
                  ) : item.fields[id] ? (
                    <DoneIcon color="primary" />
                  ) : (
                    <CloseIcon />
                  )}
                </Flex>
              ))}
            </Flex>
          </div>
        ) : null}
      </Bubble>
      {item.contact || item.hasEmail ? (
        <div className={s.responses}>
          {!collapsed ? (
            <>
              {item.thread.map(message => (
                <Bubble
                  key={message.id}
                  right={message.isHost}
                  shadow
                  color={!message.isHost ? 'primary' : 'secondary'}
                  className={clsx(s.reply, message.isHost ? s.replyIsHost : s.replyIsGuest)}
                >
                  <div className={s.comment}>{message.message}</div>
                  <BubbleTime dateTime={message.createdAt} />
                </Bubble>
              ))}
              <RsvpMessageForm rsvpId={item.id} className={s.form} onReply={refresh} />
            </>
          ) : null}
          {collapsed ? (
            <Button className={s.collapseButton} size="small" color="secondary" onClick={open}>
              <>
                <CaretDownIcon />{' '}
                {item.thread.length > 0
                  ? t(Keys.admin.rsvp.count_messages, { count: item.thread.length })
                  : t(Keys.generic.reply)}
              </>
            </Button>
          ) : null}
        </div>
      ) : (
        <p className={s.cannotReplyMessage}>
          <Info color="inherit" fontSize="inherit" />
          {t(Keys.admin.rsvp.cannot_reply)}
        </p>
      )}
    </div>
  );
};
