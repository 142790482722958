import React, { FC } from 'react';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export interface IProps {
  people: number;
  mails: number;
  texts: number;
  tokensInAccount: number;
}

export const SendMessageSummary: FC<IProps> = ({ people, mails, texts, tokensInAccount }) => {
  const t = useTranslation();

  if (people === 0) {
    return <p>{t(Keys.admin.message.send.all_guests_already)}</p>;
  }

  return (
    <>
      <p>
        {t(Keys.admin.message.sending_to, {
          count: people,
          mail: mails,
          text: texts,
        })}
      </p>

      {texts > 0 && tokensInAccount > 0 ? (
        <p>{t(Keys.admin.message.tokens_in_account, { amount: tokensInAccount })}</p>
      ) : null}
    </>
  );
};
