import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { IPartyWizardInput } from '.';

import { getService } from '#root/store';

export const Datepicker: FC<IPartyWizardInput> = ({ control }) => {
  return (
    <span className="Wizard_Input">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getService('translation').getLocale()}
      >
        <Controller
          control={control}
          name="date"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              disablePast
              slotProps={{
                textField: { variant: 'standard' },
                openPickerButton: { size: 'small' },
              }}
              value={value ? parseISO(value) : null}
              onChange={newDate => {
                if (newDate && !isNaN(newDate.getTime())) {
                  onChange(newDate.toISOString());
                }
              }}
            />
          )}
        />
      </LocalizationProvider>
    </span>
  );
};
