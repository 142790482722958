import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import React, { FC, useCallback, useState } from 'react';

import s from './index.module.scss';

import { useFetch } from '#root/hooks/use-fetch';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

interface IResult {
  attending: number;
  notAttending: number;
  noResponse: number;
}

const RsvpStats: FC<{ stat: IResult | null }> = ({ stat }) => {
  const t = useTranslation();
  if (!stat) {
    return <></>;
  }

  const data: Array<{ value: number; label: string; color: string }> = [];

  if (stat.attending > 0) {
    data.push({
      value: stat.attending,
      label: `${t(Keys.admin.rsvp.stat.attending)} (${stat.attending})`,
      color: '#00bfa5',
    });
  }
  if (stat.notAttending > 0) {
    data.push({
      value: stat.notAttending,
      label: `${t(Keys.admin.rsvp.stat.not_attending)} (${stat.notAttending})`,
      color: '#dd686e',
    });
  }
  if (stat.noResponse > 0) {
    data.push({
      value: stat.noResponse,
      label: `${t(Keys.admin.rsvp.stat.missing)} (${stat.noResponse})`,
      color: '#bebebe',
    });
  }

  return (
    <div className={s.root}>
      <PieChart
        series={[
          {
            data,
            innerRadius: 30,
            outerRadius: 80,
            paddingAngle: 6,
            cornerRadius: 10,
            arcLabel: 'value',
            arcLabelMinAngle: 20,
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: '#222',
            // fontWeight: 'bold',
          },
        }}
        width={500}
        height={200}
      />
    </div>
  );
};

export function useRsvpStats() {
  const party = useEditableParty();

  const { data: stat, load } = useFetch<IResult | null>(
    `/api/party/${party.id}/rsvp/stats`,
    null,
    {}
  );

  return {
    render: () => <RsvpStats stat={stat} />,
    load,
  };
}
